import React, { useState, useEffect } from "react";
import InstantNewsLogo from "../../components/imageComponents/instantNewsLogo";

const SearchPage = () => {
  const [searchQueryValue, setSearchQueryValue] = useState("");
  const inputName = "q";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.GATSBY_GOOGLE_CUSTOM_SEARCH_ENGINE_URL;
    script.async = true;
    document.body.appendChild(script);

    const { search } = window.location;
    let params = new URLSearchParams(search);
    setSearchQueryValue(params.get(inputName));
  }, []);

  const inputStyle = {
    outline: 0,
    width: "100%",
    padding: ".5rem 0rem .5rem 2rem",
    borderRadius: "10rem",
    border: "solid 1px #ccc",
  };

  const logoStyle = {
    width: "7rem",
  };

  return (
    <div className="container-fluid ">
      <div className="row mt-4 align-items-center">
        <div className="col-2 col-md-1 mr-2 mr-md-4 mr-lg-2">
          <a href="/">
            <div style={logoStyle}>
              <InstantNewsLogo />
            </div>
          </a>
        </div>
        <div className="col-9 col-md-8 col-lg-6 col-xl-5 pl-4 ml-1">
          <form action="/search" className="d-inline">
            <input
              style={inputStyle}
              id="google_cse_input"
              className="cse_input"
              type="text"
              name={inputName}
              value={searchQueryValue}
              onChange={({ target }) => setSearchQueryValue(target.value)}
              autoCapitalize="off"
              autoComplete="off"
            />
          </form>
        </div>
      </div>
      <div className="row offset-md-1">
        <div className="results_container col-12 col-md-9 col-lg-7 col-xl-5">
          <div className="cse_result col">
            <div className="gcse-searchresults-only"></div>
          </div>
          <div className="d-none d-lg-block amp_plas_container"></div>
        </div>
      </div>

      {/* <footer className="conducivesearh_search_footer">
        <div>Copyright adMarketplace | &nbsp;</div>
        <div>
          <a href="/terms-of-use.html">Terms Of Use</a>
          <span> | &nbsp;</span>
        </div>
        <div>
          <a href="/privacy-policy.html">Privacy Policy</a>
        </div>
      </footer> */}
    </div>
  );
};

export default SearchPage;
